import React from "react";
import { useNavigate } from "react-router-dom";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import { ROUTE_URLS, buildURL } from "../../../constant/routeURLs";

interface ISearchResultItem {
  li: React.ReactElement<HTMLLIElement>;
  itemProps: ListItemProps;
}
const SearchResultItem: React.FC<ISearchResultItem> = ({ li, itemProps }) => {
  const navigate = useNavigate();
  let brokersName = "";
  let name = "";
  let type = "";
  let navigateURL = "";
  const routeState = {
    tabToOpenOnRedirect: "Contact",
    jetCardId: null,
  };

  if (itemProps?.dataItem["jetcardid"]) {
    name = `${itemProps?.dataItem["jetCardDisplayId"]} ${itemProps?.dataItem["cardName"]}`;
    type = "Jet Card";
    navigateURL = buildURL(ROUTE_URLS.CONTACT_DETAILS_JETCARD_TAB_WITH_ID, {contactId: itemProps?.dataItem["clientId"], jetCardId: itemProps?.dataItem["jetcardid"]});
    routeState["tabToOpenOnRedirect"] = "Jet Card";
    routeState["jetCardId"] = itemProps?.dataItem["jetcardid"];
  } else if (itemProps?.dataItem["contactType"]) {
    brokersName = itemProps?.dataItem?.broker;
    name = itemProps?.dataItem["clientName"];
    type = "Contact";
    navigateURL = buildURL(ROUTE_URLS.CONTACT_DETAILS, {contactId: itemProps?.dataItem["id"]});
  } else if(itemProps?.dataItem["tripType"]) {
    name = `${itemProps?.dataItem["displayName"]} ${itemProps?.dataItem["clientName"]}`;
    type = "Trip";
    navigateURL = buildURL(ROUTE_URLS.TRIP_DETAILS, {tripId: itemProps?.dataItem["id"]});
  }

  const itemChildren = (
    <section className="suggestionsList">
      <p
        className="suggestion-left"
        onClick={() => {
          navigate(navigateURL, { state: routeState });
          // if (type === "Jet Card") {
          //   dispatch({
          //     type: SET_SELECTED_TAB_INDEX,
          //     payload: 2,
          //   });
          // }
        }}
      >
        {name}
        {brokersName && (
            <span className="broker-name">
            {brokersName}
          </span>
        )}
      </p>
      <p className="suggestion-right">{type}</p>
    </section>
  );

  return React.cloneElement(
    li,
    { ...li.props, className: "k-list-item bg-white" },
    itemChildren
  );
};

export default React.memo(SearchResultItem);
