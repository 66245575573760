import { isEmpty } from "lodash";
import { sumAmount } from "../pages/trip/manageTrip/createTrip/stepsForm/proposalForm/calculations";
import { ILeg } from "../pages/trip/manageTrip/createTrip/stepsForm/proposalForm/interface/ILeg";
import { TRIP_STATUS_MAP_LIST, usCountryCode } from "../constant/constant";
import { CONTACT_TYPE, IS_TRIP_CANCELLD, TRIP_STATUS_KEY } from "../constant/enum";
import moment from "moment";
import { ITask } from "../service/task";
import { mapValues, flatten, groupBy, orderBy } from "lodash";
import { IAirportAddress } from "../service/trip";
import { USA_COUNTRY_ID } from "./constant";
// import { getWeatherByLatAndLng } from "../service/weather";

interface Fee {
  id: number;
  name: string;
  amount: number;
}

export const breakPoint: DataType = {
  xSmall: 576,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

type DataType = {
  xSmall: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
};

export const getContactFullName = (personalInformation: any) => {
  return personalInformation.contactType === CONTACT_TYPE.INDIVIDUAL
    ? `${personalInformation.firstName || ""} ${personalInformation.lastName || ""}`
    : ` ${personalInformation.businessName || ""}`;
};

export const redirectToExternalUrl = (url: string) => {
  window.open(url, "_blank", "noreferrer");
};

export const toDataURL = (url: any) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

export const currencyFormat = (value: any, format?: any) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

export const isArrayLengthGreaterThanOne = (arr: any) => {
  try {
    return Array.isArray(arr) && arr.length > 0;
  } catch (e) {
    return false;
  }
};

export function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value);
}

export const americanConventionFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formateNumber = (value: number | null) => {
  return value ? americanConventionFormatter.format(value) : "$0.00";
};

export const convertCurrencyToNumber = (value:any) => {
  return Number(value?.replace(/[^0-9.]+/g,""))
}

export const isValidHttpUrl = (url: string): boolean => {
  // Combined regex for http, https, and www
  const urlRegex: RegExp = /^(https?:\/\/|www\.)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
  return urlRegex.test(url);
};


export const validateEmail = (email: string) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export function getAbsoluteValue(num: any) {
  try{
    return Math.abs(num);
  } catch(e) {
    return 0;
  }
}

export function validatePhoneNumber(phoneNumber: string, countryCode: string) {
  return Boolean(phoneNumber) && Boolean(countryCode);
  // If it should be implement through library check
  // const parsedNumber = parsePhoneNumber(`+${extractNumberFromString(value)}`);
  // return Boolean(parsedNumber?.isValid());
}

export function extractNumberFromString(val: string) {
  const integers = val.match(/-?\d+/g);
  if (integers) {
    const result = integers.map(number => number.replace(/-/, '')).join('');
    return result;
  } else {
    return "";
  }
}

export function maskPhoneNumber(
  phoneNumber: string = "",
  countryCode?: string,
  extension?: string,
): string {
  function maskPhoneNumber(number: string): string {
    const numberStr: string = number.toString();
    if (!String(phoneNumber).trim()) {
      return "";
    }

    // Format the number as a U.S. phone number: (XXX) XXX-XXXX
    return `(${numberStr.substring(0, 3)}) ${numberStr.substring(3, 6)}${number.length > 6 ? "-" : ""}${numberStr.substring(6, numberStr.length)}`;
  }

  return (countryCode ? ('+' + countryCode + ' ') : '')
    + maskPhoneNumber(extractNumberFromString(phoneNumber))
    + (extension && extension.trim() ? (' ext. ' + extension.trim()) : '');
}

export const formatName = (firstName: string, lastName: string, middleName?: string | null) => {
    const nameArray = [firstName, (middleName || ''), lastName];
    return nameArray.join(' ');
};

export function formatAddressRender(item: any) {
  const USA_COUNTRY_ID = 233;
  const stateName = item?.country?.id !== USA_COUNTRY_ID
  ? item?.addressesTypes?.stateName || ""
  : item?.state?.displayName || "";
  const address = [(item.address || "") , (item?.address2 || ""), (item?.city || ""), (stateName), (item?.zip || ""), (item?.country?.displayName || "")].filter((val) => !isEmpty(val.trim()));
  return address.join(", ")
}

export function formatAddressRenderNew(item: any) {
  const USA_COUNTRY_ID = 233;
  const stateName = item?.countryId !== USA_COUNTRY_ID
  ? item?.stateName || ""
  : item?.state?.displayName || "";
  const address = [(item.address || "") , (item?.address2 || ""), (item?.city || ""), (stateName ? stateName : (item?.state ? item?.state?.displayName : "")), (item?.zip || ""), (item?.country?.displayName || "")].filter((val) => !isEmpty(val.trim()));
  return address.join(", ")
}
export function formatAddress(streetAddress: string | null, city: string | null, state: string | null, country: string | null) {
  const address = [(streetAddress || ""), (city|| ""), (state || ""), (country || "")].filter((val) => !isEmpty(val.trim()));
  return address.join(", ");
}

export const formatNumberToFixed = (num: number) => num ? num.toFixed(2) : "0";

export const feeCalculation = (data: Fee[]) => {
  const Handling_Fee = data?.find((item: Fee) => item?.id === 1)?.amount || 0
  const Segment_Fee = data?.find((item: Fee) => item?.id === 2)?.amount || 0
  const Federal_Excise_Tax = data?.find((item: Fee) => item?.id === 3)?.amount || 0
  const International_Fee = data?.find((item: Fee) => item?.id === 4)?.amount || 0
  const Special_Request = data?.filter((item: Fee) => ![1, 2, 3, 4].includes(item?.id)).map((value: Fee) => value?.amount || 0)
  return {
    Handling_Fee: Handling_Fee,
    Segment_Fee: Segment_Fee,
    Federal_Excise_Tax: Federal_Excise_Tax,
    International_Fee: International_Fee,
    Special_Request: sumAmount(Special_Request)
  }
};

export const clientPayStatus = (dollars: number = 0,adjustment: number = 0) => (dollars + adjustment) !== 0;

export const downloadFileFromS3 = async (file: string, fileName: string) => {
  const responseData = await fetch(file);
  const blob = await responseData.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onloadend = () => {
    const dataURL = reader.result;
    const anchor: any = document.createElement("a");
    anchor.href = dataURL;
    anchor.download = fileName;
    anchor.click();
  };
};

export enum LegSourceType {
  DESTINATION = "DESTINATION",
  ORIGIN = "ORIGIN",
}

export const getLegFormatedAddress = (singleLeg: ILeg, legSourceType: LegSourceType) => {
  let addr1: any = ""
  let addr2: any  = "";
  let addr3: any = "";

  if(legSourceType === LegSourceType.ORIGIN) {
    addr1 = singleLeg?.originAirport?.airportAddress?.city;
    addr2 = singleLeg?.originAirport?.airportAddress?.country.id === usCountryCode ? singleLeg?.originAirport?.airportAddress?.state?.abbreviation : singleLeg?.originAirport?.airportAddress?.country?.countryCode;
    addr3 = singleLeg?.originAirport?.code;
  } else {
    addr1 = singleLeg?.destinationAirport?.airportAddress?.city;
    addr2 = singleLeg?.destinationAirport?.airportAddress?.country?.id === usCountryCode ? singleLeg?.destinationAirport?.airportAddress?.state?.abbreviation : singleLeg?.destinationAirport?.airportAddress?.country?.countryCode;
    addr3 = singleLeg?.destinationAirport?.code;
  }

  let fullAddress = "";
  if(addr1 && addr2) {
    fullAddress = `${addr1}, ${addr2}`;
  } else if(addr1 && !addr2) {
    fullAddress =  addr1;
  } else if(addr2 && !addr1) {
    fullAddress =  addr2;
  }
  return fullAddress + (addr3 ? `(${addr3})` : '');
}

export const getAirportLocationString = (airportAddress: IAirportAddress) => {
    let location = "";
    if (airportAddress.city) {
        location += (airportAddress.city + ', ');
    }
    if (airportAddress.country.id === usCountryCode) {
        if (airportAddress.state?.abbreviation) {
            location += airportAddress.state?.abbreviation;
        }
    } else if (airportAddress.country.countryCode) {
        location += airportAddress.country.countryCode;
    }
    return location;
}

export const checkIsLegDisableByTripStatusId = (statusId : number) => {
    const tripStatusDisableList = [
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.BROKER_TO_REVIEW].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.COMPLETED].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.FINALIZED].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_WITH_COST].id,
        TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_AND_FINALIZED].id,
    ];
    return tripStatusDisableList.includes(statusId);
}

export const tripCancelWithCost = (statusId: any) => {
  return [TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_AND_FINALIZED].id, TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_WITH_COST].id].includes(statusId);
}

export const checkString = (value: any) => {
  if(typeof value === "string") {
    return value;
  } else {
    return "";
  }
}

export const checkNumber = (value: any) => {
  if(typeof value === "number") {
    return value;
  } else {
    return 0;
  }
}

export const checkBoolean = (value: any) => {
  if(typeof value === "boolean") {
    return value;
  } else {
    return false;
  }
}
export const isTripCancelled = (trip: any): IS_TRIP_CANCELLD => {
  const isCancelTripWithoutCost = [TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED].id].includes(trip.tripStatusId);
  const isCancelTripWithCost = [TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_AND_FINALIZED].id, TRIP_STATUS_MAP_LIST[TRIP_STATUS_KEY.CANCELLED_WITH_COST].id].includes(trip.tripStatusId);
  if(!isCancelTripWithCost && !isCancelTripWithoutCost) {
    return IS_TRIP_CANCELLD.NO;
  } else {
    return isCancelTripWithCost ? IS_TRIP_CANCELLD.CANCELLED_WITH_COST : IS_TRIP_CANCELLD.CANCELLED_WITHOUT_COST;
  }
}

export const MAX_ALLOWED_EXPORT_ROWS = 25000;
export const MAX_ALLOWED_EXPORT_ROWS_MESSAGE = "Can’t export more than 25000 records. Please filter the records accordingly."

export const loadScript = (src: string) => new Promise<void>((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement("script"), {
        type: "text/javascript",
        defer: true,
        src,
        onerror: (e: any) => {
            reject(e);
        }
    });
    scriptElem.onload = () => {
        resolve();
    };
    document.body.appendChild(scriptElem);
});

export const getExpiryDates = () => {
  const years: number[] = [];
  const months: string[] = [
    "01", "02", "03", "04", "05", "06",
    "07", "08", "09", "10", "11", "12"
  ];

  const currentYear = new Date().getFullYear();

  for (let i = 0; i < 7; i++) {
    years.push(currentYear + i);
  }

  return {
    months,
    years
  };
};


export function sortTasksByDueDateAndPriority(tasks: Array<ITask>) {
  // Define the priority order
  const priorityOrder = {
    High: 1,
    Medium: 2,
    Low: 3,
  };

  // Group tasks by due date
  const groupedTasks = groupBy(tasks, (task) =>
    moment(task.dueDate, 'MM/DD/YYYY').format('MM/DD/YYYY')
  );

  // Sort tasks within each group by priority
  const sortedTasks = mapValues(groupedTasks, (group) =>
    orderBy(group, [(task) => priorityOrder[task.priority]], ['asc'])
  );

  // Flatten the grouped and sorted tasks back into a single array
  const result = flatten(Object.values(sortedTasks));

  return result;
}

export const convertArrayIntoString = (array: Array<string>) => {
  return array.filter((item) => !isEmpty(item)).join(", ");
}

export const isJsonString = (jsonString: string) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
}

//TODO: For future implementation
// interface IGetWeatherDetail {
//   lat: string,
//   lng: string,
//   departureTime: string,
// }
// export async function getWeatherDetail({lat, lng, departureTime}: IGetWeatherDetail) {
//   const weatherData = await getWeatherByLatAndLng(lat, lng);

// }

const roundedNumber = (number : number) : number => Math.ceil(Number(number) * 100) / 100;

export const roundUpToTwoDecimalPlaces = (number: number): number => {
  if (String(number).split(".")?.length === 2 && String(number).split(".")[1]?.length > 2) {
    const value =
      Math.floor(number) +
      Number(String(number).split(".")[1].slice(0, 4)) /
        10 ** String(number).split(".")[1].slice(0, 4).length;
    return Number(parseFloat(roundedNumber(value).toFixed(2)).toFixed(2));
  } else {
    return Number(parseFloat(number.toFixed(2)).toFixed(2));
  }
};

  // Define a function to convert URLs to links
  export const convertUrlsToLinks = (text:string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url) => `<a href="${url}" className="cursor-pointer" style='color: #00106E' target="_blank">${url}</a>`);
  };

  export const isNumber = (value: any) => {
    return typeof value === 'number';
  }

export const deleteCookie = (cookieName:any,domain:any) => {
    // Set the expiration date to a past date
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain};`;
};

export const setCookie = (cname:any, cvalue:any, exdays:any, domain:any) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = `${cname}=${cvalue};${expires};path=${domain.path};domain=${domain.domain};`;
};

export const getCookie = (cname:any) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export const isDomestic = (originId:number, destinationId:number) => {
  return originId === USA_COUNTRY_ID && destinationId === USA_COUNTRY_ID;
}
export const getAWSFileUrl = (url: string) => {
  const encodedUrl = encodeURIComponent(url);
  return `awsFilePath=${encodedUrl}`;
}
