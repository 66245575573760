export enum Handling_Fee {
  Handling_Fee_DOM = "Handling_Fee_DOM",
  Handling_Fee_JC = "Handling_Fee_INT",
  // Replacing the existing charter fee
  Handling_Fee_Charter = "Handling_Fee_Charter"
}

export enum Segment_Fee {
  Segment_Fee_DOM = "Segment_Fee_DOM",
  Segment_Fee_INT = "Segment_Fee_INT",
}

export enum International_Fee {
  International_Fee = "International_Fee",
}

export enum FET_Fee {
  "FET" = "FET",
}

export enum CC_Charges {
  CC_Charges =  "CC_Charges",
}

export const FeesTypesWithId = {
  1: Handling_Fee,
  2: Segment_Fee,
  3: FET_Fee,
  4: International_Fee,
};

export const getTypeIdFromFeeType = (type: any) => {
  let id = null;
  Object.entries(FeesTypesWithId).forEach((val) => {
    const [key, value] = val;
    if (Object.keys(value).includes(type)) {
      id = key;
    }
  });

  return id;
};

export const FeeTypeIds = {
   "Handling_Fee": "1",
   "Segment_Fee": "2",
   "FET_Fee": "3",
   "International_Fee": "4",
   "Special_Event_Fee": "25",
} 

export const TaxSurchargeTypeIds = {
  "FET": 2,
  "Segment_Fee_DOM": 5,
  "Segment_Fee_INT": 6,
  "Peak_Travel_Period": 7,
  "International_Charges": 8,
  "Handling_Fee_JC": 13
} 
