export enum ERROR_LOG_TYPE {
    FRONTEND_ERROR = "FRONTEND_ERROR"
}

export enum ERROR_LOG_SUBTYPE {
    UI = "UI",
    API = "API",
}

export enum USER_STATUS {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
};

export enum CONTACT_TYPE {
    INDIVIDUAL = 'Individual',
    BUSINESS = 'Business',
};

export enum USER_CONTACT_ROLE {
    PERSONAL = "Personal",
    BUSINESS = "Business",
    OTHER = "Other"
};

export enum JETCARD_STATUS {
    DRAFT = "Draft",
    PAYMENT_DUE = "Payment Due",
    SENT = "Sent",
    REJECTED = "Rejected",
    LIVE = "Live",
    EXHAUSTED = "Exhausted",
    INACTIVE = "Inactive",
    AWAITING_APPROVAL = "Awaiting Approval"
};

export enum JETCARD_TYPE_KEY  {
    ALL_IN = "All In",
    ALL_ACCESS = "All Access",
};

export enum AIRCRAFT_TYPE_KEY {
    LIGHT = "Light",
    MID = "Mid",
    SUPER_MID = "Super Mid",
    HEAVY = "Heavy",
    PISTON = "Piston",
    VLJ = "VLJ",
    TURBO_PROP = "Turbo Prop",
    OTHER = "Other",
}

export enum TRIP_TYPE_KEY {
    CHARTER = "CHARTER",
    JET_CARD = "JET_CARD",
}

export enum TRIP_STATUS_KEY {
    QUOTE_OPEN = 1,
    QUOTE_TO_BROKER,
    SENT_TO_CLIENT,
    ACCEPTED,
    ASSIGNMENT_MADE,
    UNKNOWN1,
    COMPLETED,
    FINALIZED,
    CANCELLED,
    CANCELLED_WITH_COST,
    CANCELLED_AND_FINALIZED,
    JET_CARD_PENDING,
    BROKER_TO_REVIEW,
    DRAFT,
    PARTIALLY_ASSIGNED,
    BROKER_TO_REVIEW2,
    QUOTE_TO_BROKER_PROPOSAL,
    QUOTE_TO_BROKER_SENT,
    JET_CARD_PENDING_PROPOSAL,
    JET_CARD_PENDING_SENT,
    ONGOING_TRIP
};

export enum TRIP_LEG_STATUS_KEY {
    IN_QUEUE,
    QUOTE_REVIEW,
    CLIENT_REVIEW,
    ACCEPTED,
    SCHEDULER_ASSIGNED,
    DRAFT,
    ONGOING_TRIP,
    IN_TRANSIT,
    COMPLETED
};

export enum TRIP_ACTION_TYPE {
    CREATE_EDIT = "CREATE_EDIT",
    MANAGE = "MANAGE",
    OTHER = "OTHER",
    INVALID = "INVALID"
}

export enum DOCUMENT_TYPES {
    PASSPORT = "Passport"
}

export enum EXCEL_CELL_FORMAT_TYPE {
    CURRENCY = "$#,##0.00",
    PERCENTAGE = "#,##0.00%"
}

export enum IS_TRIP_CANCELLD {
    NO,
    CANCELLED_WITH_COST,
    CANCELLED_WITHOUT_COST
}

export enum TASKS_PRIORITY {
    HIGH = 'High',
    MEDIUM = 'Medium',
    LOW = 'Low',
}

export enum TASK_STATUS {
    COMPLETED = "Completed",
    LATE_COMPLETED = "LateCompleted",
    UPCOMING = "Upcoming",
    OVERDUE = "Overdue"
}