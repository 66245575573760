import AxiosService from "./Service";
import { URLS, buildAPIURL } from "../utils/url";
import { updateTripDetails, updateTripOverview } from "../redux/action/trip";
import { initalizeSpecialRequestFormikForm } from "../redux/action/formikData";
import useAxios from "./Service";
import { IOptionPayload } from "../pages/trip/manageTrip/createTrip/stepsForm/tripOptionForm";
import { IClientIPAndLocation, fetchClientIPAndLocation } from "./thirdParty";
import { IActivityHistoryLog } from "../pages/trip/tripDetails/details/ActivityHistoryPanel";

export interface ISpecialRequestTypes {
  id: number;
  displayName: string;
  deleteable: boolean;
  taxable: boolean;
}

export interface ISpecialRequestPayload {
  field: string;
  operator: string;
  value: boolean;
}
export const fetchSpecialRequestTypes = async (
  specialRequestPayoad: Array<ISpecialRequestPayload>,
  callback: (specialRequestTypesList: Array<ISpecialRequestTypes>) => void
) => {
  const result = await AxiosService({
    method: "post",
    url: buildAPIURL(URLS.GetSpecialRequestTypes),
    body: JSON.stringify(specialRequestPayoad),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    callback(result.response?.responseData);
  }
};

export interface IPostTripOverviewRequest {}
export const postTripOverview = async (
  formData: any,
  callback: (response: number | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "post",
    url: buildAPIURL(URLS.PostTripOverview),
    body: JSON.stringify(formData),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(null, true);
};

export interface IPutTripOverviewRequest {}
export const putTripOverview = async (
  formData: any,
  callback: (response: number | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "put",
    url: buildAPIURL(URLS.PutTripOverview),
    body: JSON.stringify(formData),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(null, true);
};

export const fetchTripOverview = async (tripId: number,
  callback: (response: any, isError: boolean) => void) => {
  const tripOverviewApi = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripOverview, { tripId }),
  });
  if (
    tripOverviewApi?.response?.responseCode === 200 &&
    tripOverviewApi?.response?.responseData
  ) {
    callback(tripOverviewApi?.response?.responseData, false);
  } else {
    callback(null, true);
  }
}

export const fetchTripDetailsAndLegsByID = async (
  tripId: number,
  dispatch: Function,
  callback: (response: null, isError: boolean) => void
) => {
  const tripOverviewApi = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripOverview, { tripId }),
  });
  const tripLegsApi = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripLegsByTripId, { tripId }),
  });
  Promise.all([tripOverviewApi, tripLegsApi])
    .then((res) => {
      const tripOverviewResponse = res[0];
      const tripLegsResponse = res[1];
      if (
        tripOverviewResponse?.response?.responseCode === 200 &&
        tripOverviewResponse?.response?.responseData &&
        tripLegsResponse?.response?.responseCode === 200 &&
        tripLegsResponse?.response?.responseData
      ) {
        const tripDetails = {
          ...tripOverviewResponse?.response?.responseData,
          legs: tripLegsResponse?.response?.responseData,
        };
        dispatch(updateTripOverview(tripDetails));
        callback(null, false);
      } else {
        callback(null, true);
      }
    })
    .catch((err) => {
      callback(null, true);
    });
};

export const fetchTripScenarioAndOptions = async (
  tripId: number,
  callback: (response: any, isError: boolean) => void
) => {
  const tripScenarioApi = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripLegsScenarios, { tripId }),
  });
  const tripOptionsApi = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetEditTripLegsOptions, { tripId }),
  });
  Promise.all([tripScenarioApi, tripOptionsApi])
    .then((res) => {
      const tripScenarioResponse = res[0];
      const tripOptionsResponse = res[1];
      if (
        tripScenarioResponse?.response?.responseCode === 200 &&
        tripScenarioResponse?.response?.responseData &&
        tripOptionsResponse?.response?.responseCode === 200 &&
        tripOptionsResponse?.response?.responseData
      ) {
        const tripDetails = {
          scenarios: tripScenarioResponse?.response?.responseData,
          options: tripOptionsResponse?.response?.responseData,
        };
        callback(tripDetails, false);
      } else {
        callback(null, true);
      }
    })
    .catch((err) => {
      callback(null, true);
    });
};

export const fetchTripJcProposalAndJcDetails = async (
  tripId: number,
  jcId: number,
  callback: (response: any, isError: boolean) => void
) => {
  const tripJcProposalApi = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetJCProposal, { tripId }),
  });
  const tripJcDetailsApi = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetJCDetails, { jcId }),
  });
  Promise.all([tripJcProposalApi, tripJcDetailsApi])
    .then((res) => {
      const tripJcProposalResponse = res[0];
      const tripJcDetailsResponse = res[1];
      if (
        tripJcProposalResponse &&
        tripJcDetailsResponse?.response?.responseCode === 200 &&
        tripJcDetailsResponse?.response?.responseData
      ) {
        const tripJcProposalAndJcDetails = {
          jcProposalDetail: tripJcProposalResponse?.response?.responseData,
          jcDetails: tripJcDetailsResponse?.response?.responseData,
        };
        callback(tripJcProposalAndJcDetails, false);
      } else {
        callback(null, true);
      }
    })
    .catch((err) => {
      callback(null, true);
    });
};

export interface ITripSpecialRequest {
  typeId: number;
  fee: number;
  name: string;
  shortDescription: string;
  legIndex: Array<number>;
}
export const fetchTripSpecialRequestByTripId = async (tripId: number, dispatch: Function) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripSpecialRequestByTripId, { tripId }),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    const specialRequest = result.response?.responseData?.filter((item: ITripSpecialRequest) => ![1,2,3,4].includes(item.typeId));
    dispatch(initalizeSpecialRequestFormikForm(specialRequest));
  }
};

export interface IPaymentInfo {
    id: number;
    cardType: string;
    maskedCardNumber: string;
    cardExpiration: string;
    cardholderName: string;
}
export interface ITripClient {
  businessName?: string | null; 
  businessTitle?: string | null; 
  businessWebsite?: string | null; 
  contactType: string;
  id: number;
  name: string;
  email: string;
  phoneNumber: string | null;
  extension?: string;
  streetAddress: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  paymentInfo?: Array<IPaymentInfo> | null;
  countryCode?: string;
}
export interface IAirportAddress {
  city: string;
  country: {
    id: number;
    displayName: string;
    countryCode: string;
  };
  displayAddress: string;
  state: {
    id: number;
    displayName: string;
    abbreviation: string | null;
  };
  latitude?: number;
  longitude?: number;
}
export interface ITripLeg {
  id: number;
  tripId: number;
  departDate: string;
  departTime: string;
  arrivalDate: string;
  arrivalTime: string;
  displayArrivalDate: string;
  displayArrivalTime: string;
  displayDepartDate: string;
  displayDepartTime: string;
  duration: number;
  numberOfPassengers: number;
  originAirport: {
    id: number;
    code: string;
    displayName: string;
    timezone: string;
    airportAddress: IAirportAddress;
  };
  destinationAirport: {
    id: number;
    code: string;
    displayName: string;
    timezone: string;
    airportAddress: IAirportAddress;
  };
}
export interface IAircraft {
  id: number;
  name: string;
  aircraftType: string;
  imageUrl: string | null;
  operator: {
    id: number;
    name: string;
  };
  tailNumber: string;
}
export interface IOptionQuotes {
  legs: Array<number>;
  flightCost: number | null;
  aircraft: IAircraft;
}
export interface IOptionLeg extends ITripLeg {
  aircraft: IAircraft | null;
  flightCost: number | null;
  pricingHours: number;
  peak: boolean;
  international: boolean;
  fuelSurchage: boolean;
  jetCardRate: {
    aircraftType: string;
    rate: number;
  } | null;
}
export interface ITripLegOptionFee {
  autoId?: any;
  adjustment: number;
  id: number;
  name: string;
  amount: number;
  special: boolean;
}
export interface ITripLegOption {
  id: number;
  accepted?: boolean;
  optionName: string;
  showInDollars: boolean;
  flightPrice: number;
  flightAdjustment: number | null;
  pricingHours: number;
  deduction: number;
  deductionHours: number;
  allInCC: number;
  allInCash: number;
  peakAdjustment: number;
  peakAdjustmentHours: number;
  internationalAdjustment: number;
  internationalAdjustmentHours: number;
  fuelSurchageAdjustment: number;
  fuelSurchageAdjustmentHours: number;
  hideAircraftImage: boolean;
  optionQuotes: Array<IOptionQuotes>;
  optionLegs: Array<IOptionLeg>;
  fees: Array<ITripLegOptionFee>;
  notes: string | null;
}
export interface ITripDetails {
  id: number;
  tripId: string;
  status: {
    id: number;
    displayName: string;
  };
  tripType: {
    id: number;
    displayName: string;
  };
  client: ITripClient;
  tripLegs: Array<ITripLeg>;
  tripLegOptions: Array<ITripLegOption>;
  skipPayment: boolean;
  ccChargePercent: number;
  notes: string | null;
  conclusion: string | null;
  introduction: string | null;
  termsConditions: string | null;
  emergencyContactName: string | null;
  emergencyContactNumber: string | null;
  authorizedName: string | null;
  authorizedEmail: string | null;
  authorizedSignature: string | null;
  authorizedTimestamp: string | null;
  authorizedIpAddress: string | null;
  jetCard: any;
  brokers: Array<ITripBroker>
  tripRoute: string;
  tripRouteObj: ITripRouteObj;
}
export const fetchTripDetailsByTripId = async (
  tripId: number,
  callback: (tripDetail: ITripDetails | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripDetailsByTripId, { tripId }),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(null, true);
};
export interface ICreatedBy {
    id: number;
    name: string;
};
export interface IAircraftTypes {
    id: number;
    displayName: string;
};
export interface ITripBroker {
    id: number;
    isHandler: boolean;
    name: string;
};
export interface IFBO {
    id: number;
    name: number;
};
export interface IAirport {
    airportAddress: IAirportAddress;
    code: string;
    displayName: string;
    fbos: Array<IFBO>;
    id: number;
    name: string;
    timezone: string;
};
export interface ITripPassenger {
    id: number;
    firstName: string;
    lastName: string;
};
export interface IJetCardRate {
    aircraftType: string;
    rate: number;
};

export interface ILegStatus {
  id: number;
  displayName: string;
}
export interface ILeg {
    additionalNotes: string,
    aircraft: IAircraft | null;
    arrivalDate: string;
    arrivalFBO: IFBO | null;
    arrivalTime: string;
    catering: string;
    departDate: string;
    departureFBO: IFBO | null;
    departTime: string;
    destinationAirport: IAirport;
    displayArrivalDate: string;
    displayArrivalTime: string;
    displayDepartDate: string;
    displayDepartTime: string;
    duration: number;
    flightCost: number | null;
    fuelBasisPoint: number | null;
    fuelPercent: number | null;
    fuelSurchage: boolean;
    groundNeeds: string;
    id: number;
    international: boolean;
    internationalPercent: number | null;
    jetCardRate: IJetCardRate | null;
    leadPassengerId: number;
    legId: number;
    legStatus: ILegStatus | null;
    numberOfPassengers: number;
    operatorCost: number;
    originAirport: IAirport;
    passengers: Array<ITripPassenger>
    peak: boolean;
    peakPercent: number | null;
    pricingHours: number;
    scheduler: null;
    tripId: number;
};
export interface IPassengerLeg {
    applied: boolean;
    lead: boolean;
}
export interface IPassengerDetail {
    dob: string;
    email: string;
    id: number;
    legs: Array<IPassengerLeg>;
    name: string;
    phone: string;
    weight: string;
}
export interface IFullTripLegOption {
    fees: Array<ITripLegOptionFee>;
    flightAdjustment: number | null;
    flightPrice: number;
    fuelSurchageAdjustment: number;
    fuelSurchageAdjustmentHours: number;
    internationalAdjustment: number;
    internationalAdjustmentHours: number;
    hideAircraftImage: boolean;
    id: number;
    notes: string | null;
    optionLegs: Array<ILeg>;
    optionName: string;
    peakAdjustment: number;
    peakAdjustmentHours: number;
    pricingHours: number;
    showInDollars: boolean;
    totalOperatorCost: number;
    margin: number;
};
export interface INote {
    id: number;
    clientId: number;
    tripId: number;
    notes: string;
    isPrivate: boolean;
    isPinned: boolean;
    createdBy: ICreatedBy;
    modifiedDate: string;
};
export interface ITripRouteObj {
    roundTrip: boolean;
    routes: Array<Array<string>>;
}
export interface IClientPayment {
  amountDue: number;
  amountPaid: number;
  billToClient: boolean;
  billToName: string;
  billingAddress1: string;
  billingNotes: string;
  ccHold: string;
  ccHoldExpiration: string;
  dueDate: string;
  invoiceNotes: string;
  invoiceNumber: string;
  billingAddress2: string;
  billingCiy: string;
  billingState: string;
  billingZip: string;
  billingCountry: {
    id: number;
    displayName: string;
    countryCode: string;
  };
  invoiceSent: boolean;
}

export interface ITripCommon {
    aircraftTypes: Array<IAircraftTypes>;
    brokers: Array<ITripBroker>;
    ccChargePercent: number;
    client: ITripClient;
    clientPayment: IClientPayment | null;
    id: number;
    jetCard: any;
    status: {
        id: number;
        displayName: string;
    };
    tripId: string;
    tripRoute: string;
    tripRouteObj: ITripRouteObj;
    tripType: {
        id: number;
        displayName: string;
    };
}

export interface IAssociatedTrip {
  id: Number;
  displayName: string;
  tripRoutes: string;
  routes: string;
  tripStartDate: string;
  tripStatus: string;
  created: string;
}

export interface IPaymentHistory {
  transactionId:number;
  dateTime:string;
  name:string;
  cardNumber:string;
  amount:number;
}

export interface IPermissions {
  VIEWTRIPFINANCEDETAIL: boolean;
  EDITTRIPS: boolean;
  CANCELTRIP: boolean;
  EDITLEGTRIPREVIEW: boolean;
  EDITLEGFINANCEREVIEW: boolean;
  EDITREVENUETRIPREVIEW: boolean;
  EDITREVENUEFINANCEREVIEW: boolean;
  EDITHANDLINGFINANCEREVIEW: boolean;
  EDITHANDLINGTRIPREVIEW: boolean;
  EDITINTERNATIONALTRIPREVIEW: boolean;
  EDITINTERNATIONALFINANCEREVIEW: boolean;
  EDITSPECIALTRIPREVIEW: boolean;
  EDITSPECIALFINANCEREVIEW: boolean;
  PREVFININV: boolean;
  SAVEFNLZTRIP: boolean;
  BROKERTOREV: boolean;
  CONFIRMSENDFIN: boolean;
  RPTTRIPISSUE: boolean;
  PREVCLNTINV: boolean;
  UNFINALZ: boolean;
}

export interface IFullTripDetails extends ITripCommon {
    authorizedName: string | null;
    authorizedEmail: string | null;
    authorizedSignature: string | null;
    authorizedTimestamp: string | null;
    cancellationReason: string | null;
    emergencyContactName: string | null;
    emergencyContactNumber: string | null;
    emailRecipient: {
      to: Array<string> | null;
      cc: Array<string> | null;
      bcc: Array<string> | null;
    }
    legs: Array<ILeg>;
    notes: string;
    restarted: boolean;
    passengerDetails: Array<IPassengerDetail>;
    permissions: IPermissions; 
    skipPayment: boolean;
    termsConditions: string;
    tripLegOption?: IFullTripLegOption | null;
    tripNotes: Array<INote>;
    verbalQuote: number;
    associatedTrips: Array<IAssociatedTrip> | [];
    paymentDetails: Array<IPaymentHistory> | [];
};
export const fetchFullTripDetailsByTripId = async (
  tripId: number,
  callback: (tripDetails: IFullTripDetails | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetFullTripDetailsByTripId, { tripId }),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(null, true);
};

export const fetchAcceptedTripDetailsByTripId = async (
  tripId: number,
  dispatch: Function,
  callback: (response: null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetAcceptedTripDetailsByTripId, { tripId }),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    dispatch(updateTripDetails(result.response?.responseData));
    callback(null, false);
    return;
  }
  callback(null, true);
};

export interface ITripError {
    errorCode: string;
    errorMessage: string;
}
export const fetchTripDetailsByTripIdAndHash = async (
    tripId: number,
    hash: string,
    callback: (tripDetail: ITripDetails | ITripError | null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetTripDetailsByTripIdAndHash, { tripId, hash }),
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
        return;
    }
    callback(null, true);
};

export interface IInvoiceTripDetails extends ITripCommon {
    tripLegOption: IFullTripLegOption;
};
export enum INVOICE_TYPE {
    FINAL = "final",
    PRELIMINARY = "preliminary",
};
export const fetchInvoiceDetailsByTripId = async (
    tripId: number,
    invoiceType: INVOICE_TYPE,
    callback: (tripDetail: IInvoiceTripDetails | null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetInvoiceDetailsByTripId, { tripId }, { invoiceType }),
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
        return;
    }
    callback(null, true);
};

export const fetchInvoiceDetailsByTripIdAndHash = async (
    tripId: number,
    hash: string,
    invoiceType: INVOICE_TYPE,
    callback: (tripDetail: IInvoiceTripDetails | ITripError | null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetInvoiceDetailsByTripIdAndHash, { tripId, hash }, { invoiceType }),
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
        return;
    }
    callback(null, true);
};

export const getTripOptionLegs = async (tripId: string) => {
  const { response, status } = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripLegs, { tripId }),
  });
  if (status === 200) {
    if (response.responseCode === 200) {
      return response.responseData;
    }
  }
  return [];
};

export const tripCreateOption = async (
  formData: { tripId: number | undefined; options: IOptionPayload[] },
  method: string,
  callback: (response: any | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method,
    url: buildAPIURL(URLS.CreateOption),
    body: JSON.stringify(formData),
  });
  if (result?.status === 200 || result?.status === 201) {
    if (result && result.response?.responseCode === 201) {
      callback(result.response?.responseMessage, false);
      return;
    }
  }
  callback(result?.response?.responseMessage || "Something went wrong", true);
};

export const FetchScenarioFeesTypeAndTripSurcharge = async (
  callback: (response: any, isError: boolean) => void
) => {
  const scenarioFeesTypeApi = await useAxios({
    url: "/trip/specialrequesttypes",
    method: "post",
    body: JSON.stringify([]),
  });
  const tripSurchargeApi = await useAxios({
    url: "/agreement/jetcard/tax-surchage",
    method: "get",
  });
  Promise.all([scenarioFeesTypeApi, tripSurchargeApi])
    .then((res) => {
      const scenarioFeesTypeApiResponse = res[0];
      const tripSurchargeApiResponse = res[1];
      if (
        scenarioFeesTypeApiResponse?.response?.responseCode === 200 &&
        scenarioFeesTypeApiResponse?.response?.responseData &&
        tripSurchargeApiResponse?.response?.responseCode === 200 &&
        tripSurchargeApiResponse?.response?.responseData
      ) {
        const triScenarioFeesTypeAndTripSurcharge = {
          taxSurcharge: scenarioFeesTypeApiResponse?.response?.responseData,
          tripTaxSurcharge: tripSurchargeApiResponse?.response?.responseData,
        };
        callback(triScenarioFeesTypeAndTripSurcharge, false);
      } else {
        callback(null, true);
      }
    })
    .catch((err) => {
      callback(null, true);
    });
};

export const FetchTripList = async(payload: any, callback: (response: any, isError: boolean, total?: number) => void) => {
  const { response, status } = await useAxios({
    method: "post",
    url: URLS.GetTripListing,
    headers: JSON.stringify({ accept: "*/*" }),
    body: JSON.stringify(payload),
  });
  if (status === 200) {
    if (response?.responseCode === 200) {
      if(Array.isArray(response?.responseData?.data)) {
        callback(response?.responseData?.data, false, response?.responseData?.total);
      } else {
        callback("Something went wrong. Try again later", true);
      }
    } else {
      callback("Something went wrong. Try again later", true);
    }
  } else {
    callback("Something went wrong. Try again later", true);
  }
}

export const FetchTripOperator = async () => {
  const tripOperator = await useAxios({
    method: "get",
    url: `${URLS.GetTripOperator}`,
    body: JSON.stringify({}),
    headers: JSON.stringify({ accept: "*/*" }),
  });

  return tripOperator;

}
export const FetchTripAirports = async () => {
  const tripOperator = await useAxios({
    method: "get",
    url:`${URLS.GetTripAirports}`,
    body: JSON.stringify({}),
    headers: JSON.stringify({ accept: "*/*" }),
  });

  return tripOperator;

}
export const FetchTripOperatorAndAircraft = async (
  callback: (response: any, isError: boolean) => void
) => {
  const tripOperator = await useAxios({
    method: "get",
    url: `${URLS.GetTripOperator}`,
    body: JSON.stringify({}),
    headers: JSON.stringify({ accept: "*/*" }),
  });
  const tripAircraft = await useAxios({
    method: "get",
    url: `${URLS.GetTripAircraft}`,
    body: JSON.stringify({}),
    headers: JSON.stringify({ accept: "*/*" }),
  });
  Promise.all([tripOperator, tripAircraft])
    .then((res) => {
      const tripOperatorApiResponse = res[0];
      const tripAircraftApiResponse = res[1];
      if (
        tripOperatorApiResponse?.response?.responseCode === 200 &&
        tripAircraftApiResponse?.response?.responseCode === 200
      ) {
        const operatorDataList = tripOperatorApiResponse?.response?.responseData?.map((item: any) => {
          return { id: item?.id, name: item?.name };
        });

        const airCraftDataList = tripAircraftApiResponse?.response?.responseData?.map((item: any) => {
          return { id: item?.id, name: `${item?.name} / ${item?.tailNumber}`, operatorId: item?.operatorId };
        });

        const data = {
          tripOperator: operatorDataList,
          tripAircraft: airCraftDataList,
        };
        callback(data, false);
      } else {
        callback(null, true);
      }
    })
    .catch((err) => {
      callback(null, true);
    });
};

export const fetchTripProposalTerm = async (
  tripId: any,
  callback: (respose: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: "/trip/proposal/terms/" + tripId,
    method: "get",
    body: JSON.stringify([]),
  });

  if (result?.status === 200) {
    if (result?.response?.responseData) {
      callback(result?.response?.responseData, false);
      return;
    }
  }
  callback(result?.response?.responseMessage || "Something went wrong", true);
};

export const saveTripProposalTerm = async (
    tripProposalTermPayload: any,
    callback: (response: any, isError: boolean) => void
) => {
    fetchClientIPAndLocation(async (response: IClientIPAndLocation | null, isError: boolean) => {
        if (response && !isError) {
            const payload = {
                ...tripProposalTermPayload,
                acceptTrip: {
                    authorizedIpAddress: response.ipAddress,
                    authorizedIpCity: response.city,
                    authorizedIpCountry: response.countryName,
                    authorizedIpState: response.stateProv,
                    authorizedIpPin: '',
                }
            }
            const result = await AxiosService({
                url: "/trip/proposal/send",
                method: "post",
                body: JSON.stringify(payload),
            });
            if (result && result.response?.responseCode === 200) {
                callback(result.response?.responseData, false);
            } else {
                callback(null, true);
            }
        } else {
            callback(null, true);
        }
    });
};

export interface IAircraftRate {
  id: number;
  aircraftType: string;
  aircraftTypeId: number;
  minDailyHours: number;
  preferred: boolean;
  rate: number;
  hours?: number;
}
export interface IAllAccessRate {
  id: number;
  aircraftType: string;
  aircraftTypeId: number;
  rate: number;
  value: string;
}
export interface ICurrentAircraftRate {
  aircraftType: { id: number; displayName: string };
  id: number;
  minRates: number;
  rangeFrom: number;
  rangeTo: number;
}
export interface IRates {
  conversionRates: IAircraftRate[];
  allAccessRates: IAllAccessRate[];
}
export const GetConversionRatesAndAircraftTypes = async (
  purchasedHours: number,
  preferredAircraft: IAircraftRate | undefined,
  callback: (response: IRates | null, isError: boolean) => void
) => {
  const { response, status } = await useAxios({
    method: "get",
    url: URLS.GetAircraftRates,
    headers: JSON.stringify({ accept: "*/*" }),
    body: null,
  });

  if (status === 200) {
    const rates: IRates = {
      conversionRates: [],
      allAccessRates: [],
    };
    response?.responseData?.forEach((item: any) => {
      if (preferredAircraft && item.jetCardType.displayName === "All In") {
        const isRangeToZero =
          item.rangeTo === 0 &&
          (purchasedHours > item.rangeFrom || purchasedHours === item.rangeFrom);
        if (
          isRangeToZero ||
          (purchasedHours > item.rangeFrom && purchasedHours < item.rangeTo + 1) ||
          purchasedHours === item.rangeFrom ||
          purchasedHours === item.rangeTo
        ) {
          rates.conversionRates = item.details
            .map((item: ICurrentAircraftRate) => {
              const isPreferredAircraft: boolean =
                item.aircraftType.id === preferredAircraft.aircraftTypeId;
              return {
                aircraftTypeId: item.aircraftType.id,
                aircraftType: item.aircraftType.displayName,
                rate: isPreferredAircraft ? preferredAircraft.rate : item.minRates,
                hours: isPreferredAircraft
                  ? 1.0
                  : Number((item.minRates / preferredAircraft.rate).toFixed(2)),
                preferred: isPreferredAircraft,
              };
            })
            .sort((a: IAircraftRate, b: IAircraftRate) => a.aircraftTypeId - b.aircraftTypeId);
        }
      } else {
        rates.allAccessRates = item.details
          .map((item: ICurrentAircraftRate) => ({
            aircraftTypeId: item.aircraftType.id,
            aircraftType: item.aircraftType.displayName,
            rate: item.minRates,
            value: `${item.aircraftType.displayName} - ${item.minRates}`,
          }))
          .sort((a: IAllAccessRate, b: IAllAccessRate) => a.aircraftTypeId - b.aircraftTypeId);
      }
    });
    callback(rates, false);
  } else {
    callback(null, true);
  }
};

export const tripCreateProposal = async (
  payload: any,
  method: string,
  callback: (response: any | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method,
    url: buildAPIURL(URLS.CreateProposal),
    body: JSON.stringify(payload),
  });
  if (result?.status === 200 || result?.status === 201) {
    if (result && result.response?.responseCode === 201 && result.response?.status) {
      callback(result.response?.responseMessage, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const tripCreateProposalCharter = async (
  payload: any,
  method: string,
  callback: (response: any | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method,
    url: buildAPIURL(URLS.CreateCharterProposal),
    body: JSON.stringify(payload),
  });
  if (result?.status === 200 || result?.status === 201) {
    if (result && result.response?.responseCode === 201 && result.response?.status) {
      callback(result.response?.responseMessage, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const fetchTripDetailsById = async (
  tripId: number,
  dispatch: Function,
  callback: (response: null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTripDetailsByTripId, { tripId }),
  });
  if (result && result.response?.responseCode === 200 && result.response?.responseData) {
    dispatch(updateTripDetails(result.response?.responseData));
    callback(null, false);
    return;
  }
  callback(null, true);
};

export const sendTripReminderEmail = async (
  tripId: any,
  payload: any,
  method: string,
  callback: (response: any | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method,
    url: buildAPIURL(URLS.SendTripReminderEmail, {tripId}),
    body: JSON.stringify(payload),
  });
  if (result?.status === 200) {
    if (result && result.response?.responseCode === 200 && result.response?.status) {
      callback(result.response?.responseMessage, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export interface IPaymentGetWayInfo {
    "sessionToken": string; 
    "card": null | {
        "saveCard": boolean;
        "ccTempToken": string;
    };
    "secureSavedCard": null | {
      "id": number;
  };
  "zipCode"?: string | null
}
export interface ITripProposalAcceptedPayload {
    "tripId": number;
    "scenarioOptionId": number;
    "authorizedName": string;
    "authorizedEmail": string;
    "emergencyContactName": string;
    "emergencyContactNumber": string;
    "authorizedSignature": string;
    "paymentInfo": IPaymentGetWayInfo | null;
}
export const tripProposalAccepted = (
  tripProposalAcceptedPayoad: ITripProposalAcceptedPayload,
  callback: (response: any, isError: boolean) => void
) => {
    fetchClientIPAndLocation(async (response: IClientIPAndLocation | null, isError: boolean) => {
        const payload = {
            ...tripProposalAcceptedPayoad,
            authorizedIpAddress: response?.ipAddress || null,
            authorizedIpCity: response?.city || null,
            authorizedIpCountry: response?.countryName || null,
            authorizedIpState: response?.stateProv || null,
            authorizedIpPin: '',
        }
        const result = await AxiosService({
          method: "post",
          url: buildAPIURL(URLS.PostProposalAccepted),
          body: JSON.stringify(payload),
        });
        if (result && [200, 201, 202].includes(result.response?.responseCode) && result.response?.responseData) {
          callback(result.response?.responseData, false);
        } else {
          callback(result.response?.responseMessage, true);
        }
    });
};

export const deletePaymentInfoByTripIdAndPayId = async (
    tripId: number,
    payId: number,
    hash: string,
    callback: (response: any, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "delete",
        url: buildAPIURL(URLS.DeletePaymentInfoByTripIdAndPayId, { tripId, hash, payId }),
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
    } else {
        callback(null, true);
    }
}

export const saveTripManageFrom = async (
  payload: any,
  callback: (response: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: buildAPIURL(URLS.TripManage),
    method: "post",
    body: JSON.stringify(payload),
  });
  if (result?.status === 200 || result?.status === 201) {
    if ([200, 201].includes(result?.response?.responseCode)) {
      callback(result?.response?.responseData, false);
    } else {
      callback("Something went wrong", true);
    }
    return;
  } else {
    callback("Something went wrong", true);
  }
};

export const updateTripManageLeg = async (
  payload: any,
  callback: (response: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: buildAPIURL(URLS.UpdateTripManageLeg),
    method: "post",
    body: JSON.stringify(payload),
  });

  if (result?.status === 200 || result?.status === 201) {
    if ([200, 201].includes(result?.response?.responseCode)) {
      callback(result?.response?.responseData, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
    return;
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const tripManageFinanceAdjustment = async (
  payload: any,
  callback: (response: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: buildAPIURL(URLS.TripManageFinanceAdjustment),
    method: "post",
    body: JSON.stringify(payload),
  });

  if (result?.status === 200 || result?.status === 201) {
    if ([200, 201].includes(result?.response?.responseCode)) {
      callback(result?.response?.responseData, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
    return;
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const tripManageConfirmSendToFinance = async (
  tripId: number,
  payload: any,
  callback: (response: number | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "put",
    url: buildAPIURL(URLS.TripManageConfirmSendToFinance, { tripId }),
    body: JSON.stringify(payload),
  });
  if ([200, 201].includes(result?.response?.responseCode)) {
    callback(result.response?.responseCode, false);
    return;
  }
  callback(null, true);
};

export const getTripFinanceSendInvoice = async (
  tripId: number,
  callback: (response: number | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetFinanceSendInvoice, { tripId }),
  });
  if ([200, 201].includes(result?.response?.responseCode)) {
    callback(result.response?.responseCode, false);
    return;
  }
  callback(null, true);
};

export const getTaxSurcharge = async (
  callback: (response: number | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.GetTaxSurcharge),
  });
  if ([200, 201].includes(result?.response?.responseCode)) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(null, true);
};

export interface ICancelTrip {
  tripId: number;
  cancellationReason: string;
  cancelWithCost: boolean;
}
export const cancelTrip = async (
  data: ICancelTrip,
  callback: (response: number | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "post",
    url: buildAPIURL(URLS.CancelTrip),
    body: JSON.stringify(data),
  });
  if ([200, 201].includes(result?.response?.responseCode)) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(null, true);
};

export interface IAssignPassengersToTripPayoad {
    tripId: number;
    legs: Array<{
        id: number;
        passengerIds: Array<number>;
        leadPassengerId?: number;
    }>
}
export const assignPassengersToTrip = async (
    assignPassengersToTripPayoad: IAssignPassengersToTripPayoad,
  callback: (response: any, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "post",
        url: buildAPIURL(URLS.PostAssignPassengerToTrip),
        body: JSON.stringify(assignPassengersToTripPayoad),
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
    } else {
        callback(null, true);
    }
}

export const getTripFiles = async (
  tripId: number,
  callback: (response: any | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(URLS.TripDownloadFiles, { tripId }),
  });
  if ([200, 201].includes(result?.response?.responseCode)) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(null, true);
  callback(result?.response?.responseMessage || "Something went wrong", true);
};

export const deleteTripFiles = async (
  tripId: number,
  callback: (response: number | null, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "DELETE",
    url: buildAPIURL(URLS.TripDownloadFiles, { tripId }),
  });
  if ([200, 201].includes(result?.response?.responseCode)) {
    callback(result.response?.responseData, false);
    return;
  }
  callback(result?.response?.responseMessage || "Something went wrong", true);
};

export const sendTripFiles = async (
  payload: any,
  callback: (response: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: buildAPIURL(URLS.SendTripFiles),
    method: "post",
    body: JSON.stringify(payload),
  });

  if (result?.status === 200 || result?.status === 201) {
    if ([200, 201].includes(result?.response?.responseCode)) {
      callback(true, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
    return;
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const updateTripFiles = async (
  payload: any,
  callback: (response: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: buildAPIURL(URLS.UpdateTripFiles),
    method: "put",
    body: JSON.stringify(payload),
  });

  if (result?.status === 200 || result?.status === 201) {
    if ([200, 201].includes(result?.response?.responseCode)) {
      callback(result?.response?.responseData, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
    return;
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const sendDownloadTripFiles = async (
  filePath: string,
  callback: (response: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: buildAPIURL(`${URLS.SendDownloadTripFiles}?${filePath}`),
    method: "get",
  });

  if (result?.status === 200 || result?.status === 201) {
    if ([200, 201].includes(result?.response?.responseCode)) {
      callback(result?.response?.responseData, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
    return;
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const getTripLegsByIds = async (
  payload: number[],
  callback: (response: any, isError: boolean) => void
) => {
  const result = await AxiosService({
    url: buildAPIURL(URLS.GetTripLegsByIds),
    method: "post",
    body: JSON.stringify(payload),
  });

  if (result?.status === 200 || result?.status === 201) {
    if ([200, 201].includes(result?.response?.responseCode)) {
      callback(result?.response?.responseData, false);
    } else {
      callback(result?.response?.responseMessage || "Something went wrong", true);
    }
    return;
  } else {
    callback(result?.response?.responseMessage || "Something went wrong", true);
  }
};

export const putTripReset = async (
    tripId: number,
    callback: (response: null, isError: boolean) => void
) => {
    const result = await AxiosService({
      method: "put",
      url: buildAPIURL(URLS.PutTripReset, { tripId }),
    });
    if (result && result.response?.responseCode === 200) {
      callback(null, false);
      return;
    }
    callback(null, true);
};

export interface ITripDropdwon {
    id: number;
    displayName: string;
}
export interface ITripDropdwonList {
    total: number;
    aggregates: unknown;
    data: Array<ITripDropdwon>;
}
export const fetchTripWithFilter = async (payload: object, callback: (tripDropdwonList: ITripDropdwonList | null, isError: boolean) => void) => {
    const result = await AxiosService({
        method: "post",
        body: JSON.stringify(payload),
        url: buildAPIURL(URLS.PostTripSearch)
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
    } else {
        callback(null, true);
    }
};

export interface IRating {
    recommendation: number | null;
    flightCrew: number | null;
    pilotCommunication: number | null;
    overallService: number | null;
    jetsExperience: string | null;
    bookingFlightExperience: string | null;
    addtionalServiceRecommendation: string | null;
    other: string | null;
};
export interface ITripFeedbackDetail {
    tripId: string;
    client: ITripClient;
    rating:  IRating;
    feedbackSubmitted: boolean;
};
export const fetchTripFeedback = async (
    tripId: number,
    callback: (tripFeedbackDetail: ITripFeedbackDetail | null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetTripSubmitFeedback, { tripId }),
    });
    if (result && result.response?.responseCode === 200) {
        callback(result.response?.responseData, false);
    } else {
        callback(null, true);
    }
};

export const fetchTripFeedbackWithHash = async (
    tripId: number,
    hash: string,
    callback: (tripFeedbackDetail: ITripFeedbackDetail | null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetTripSubmitFeedbackWithHash, { tripId, hash }),
    });
    if (result && result.response?.responseCode === 200 && result.response?.responseData) {
        callback(result.response?.responseData, false);
    } else {
        callback(null, true);
    }
};

export const sendTripFeedbackFormEmail = async (
    tripId: number,
    callback: (_: null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetSendTripFeedbackEmail, { tripId }),
    });
    if (result && result.response?.responseCode === 200) {
        callback(null, false);
    } else {
        callback(null, true);
    }
};

export interface ITripFeedbackSubmittedPayoad extends IRating {
    tripId: number,
    clientId: number,
}
export const tripFeedbackSubmitted = async (
    tripId: number,
    hash: string,
    tripFeedbackSubmittedPayoad: ITripFeedbackSubmittedPayoad,
    callback: (_: null, isError: boolean) => void
) => {
    const result = await AxiosService({
        method: "post",
        url: buildAPIURL(URLS.PostTripSubmitFeedbackWithHash, { tripId, hash }),
        body: JSON.stringify(tripFeedbackSubmittedPayoad),
    });
    if (result && result.response?.responseCode === 200) {
        callback(null, false);
    } else {
        callback(null, true);
    }
}

export interface IActivityHistoryLogParams {
    tripId?: number;
    legId?: number;
    jetCardTypeRateId?: number;
    userId?: number;
    contactId?: number;
}

enum ACTION {
  Add = "Add",
  Delete = "Delete"
}

export const getActivityHistoryLog = async (
  url: string,
  params: IActivityHistoryLogParams,
  callback: (response: IActivityHistoryLog[] | string, isError: boolean) => void
) => {
  const result = await AxiosService({
    method: "get",
    url: buildAPIURL(url, params),
  });
  if ([200, 201].includes(result.response?.responseCode)) {
    const activityHistory = result.response?.responseData?.map((item:IActivityHistoryLog) => {
      const previosValue = item?.action === ACTION.Add ? "-" : item?.previosValue;
      const newValue = item?.action === ACTION.Delete ? "-" : item?.newValue;
      return {
        ...item,
        previosValue,
        newValue
      };
    }) || null
    callback(activityHistory, false);
  } else {
    callback("Something went wrong", true);
  }
};

export const triggerTripProposalViewEmail = async (tripId: number, callback?: (_: null, isError: boolean) => void) => {
    const result = await AxiosService({
        method: "get",
        url: buildAPIURL(URLS.GetTriggerTripProposalViewEmail, { tripId })
    });
    if (result && result.response?.responseCode === 200) {
        callback && callback(null, false);
    } else {
        callback && callback(null, true);
    }
};