import * as type from "../../constant/redux-const";
import { DEFAULT_PAGE_SIZE } from "../../utils/constant";
import { JETCARD_STATUS } from "../../constant/enum";

export const initialContactJetCardFilter = {
  status: [
    {
      id: 3,
      text: JETCARD_STATUS.LIVE,
      expanded: true,
      checkIndeterminateField: false,
    },
    {
      id: 4,
      text: JETCARD_STATUS.EXHAUSTED,
      expanded: true,
      checkIndeterminateField: false,
    },
    {
      id: 7,
      text: JETCARD_STATUS.PAYMENT_DUE,
      expanded: true,
      checkIndeterminateField: false,
    },
    {
      id: 8,
      text: JETCARD_STATUS.AWAITING_APPROVAL,
      expanded: true,
      checkIndeterminateField: false,
    },
  ],
}
const initialState: any = {
  promotionFilter: {
    promotionName: "",
    startDate: "",
    endDate: "",
    promotionType: "",
    status: "",
  },
  promotionFilterloaded: false,
  dataState: {
    filter: {
      filters: [],
      logic: "and",
    },
    sort: [{ field: "id", dir: "desc" }],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },

  agrementFilter: {
    cardType: "",
    clientName: "",
    brokers: [],
    displayName: "",
    startCreatedDate: "",
    endCreatedDate: "",
    hideFundsForClient: false,
  },
  agrementFilterloaded: false,
  agrementDataState: {
    filter: {
      filters: [],
      logic: "and",
    },
    sort: [{field: "jetcardid", dir: "desc"}],
    take: DEFAULT_PAGE_SIZE,
    skip: 0,
  },
  contactJetCardFilter: initialContactJetCardFilter,
};

const JetCardReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case type.JETCARD_PROMOTION_FILTER_CHANGE_HANDLER:
      return {
        ...state,
        promotionFilter: {...action.payload},
      };
    case type.JETCARD_FILTER_LOADED: 
      return {
        ...state,
        dataState: action.payload,
      }
    case type.JETCARD_AGREMENT_FILTER_CHANGE_HANDLER:
      return {
        ...state,
        agrementFilter: {...action.payload},
      };
    case type.JETCARD_AGREMENT_FILTER_LOADED: 
      return {
        ...state,
        agrementDataState: action.payload,
      }
    case type.CONTACT_JETCARD_FILTER :
      return{
        ...state ,
        contactJetCardFilter : action.payload
      }
    default:
      return state;
  }
};

export default JetCardReducer;
